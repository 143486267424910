body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.datePickerError .MuiFormHelperText-root,
.datePickerError .MuiInputLabel-root {
  color: #d32f2f !important;
}
.datePickerError .MuiInput-root:after{
  color: #d32f2f !important;
  -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    border-bottom: 2px solid #d32f2f;
}
/* .MuiInputLabel-root
.MuiInput-root:after */